<template>
  <div class="h-full w-full grid-cols-1" v-if="Object.values(isDataReady).every(v => v === true)">
    <div class="flex h-full w-full flex-col space-y-4 pb-0 pt-2" :class="selectedAlertType == 'Pair' ? '' : 'px-4'">
      <AlertsCustomVisualizationCoin v-if="selectedAlertType == 'Coin'" :alert="alert" class="h-full" />
      <AlertsCustomVisualizationNewsFeed v-else-if="selectedAlertType == 'News'" :alert="alert" class="h-full" />
      <AlertsCustomVisualizationSEC v-else-if="selectedAlertType == 'SEC'" :alert="alert" class="h-full" />
      <AlertsCustomVisualizationResearch
        v-else-if="selectedAlertType == 'ResearchArticle'"
        :alert="alert"
        class="h-full"
      />
      <AlertsCustomVisualizationOnchain
        v-else-if="selectedAlertType == 'OnchainTransaction'"
        :alert="alert"
        class="h-full"
      />
      <AlertsCustomVisualizationGas v-else-if="selectedAlertType == 'Gas'" :alert="alert" class="h-full" />
      <AlertsCreateVisualizationUnlock
        v-else-if="selectedAlertType == 'Unlock'"
        :alert="alert"
        :unlock-coins="unlockCoins"
        class="h-full"
      />
      <AlertsCustomVisualizationPair v-else-if="selectedAlertType == 'Pair'" :alert="alertConfig" class="h-full" />
    </div>
  </div>
  <div v-else class="flex flex-col pr-8">
    <BaseLoader class="m-auto h-5 w-5" />
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { ref, onMounted, inject } from 'vue';
import useEmitter from '@/composeables/emitter';
import AlertsCustomVisualizationCoin from '@/components/alerts/custom/visualization/AlertsCustomVisualizationCoin.vue';
import AlertsCustomVisualizationNewsFeed from '@/components/alerts/custom/visualization/AlertsCustomVisualizationNewsFeed.vue';
import AlertsCustomVisualizationSEC from '@/components/alerts/custom/visualization/AlertsCustomVisualizationSEC.vue';
import AlertsCustomVisualizationGas from '@/components/alerts/custom/visualization/AlertsCustomVisualizationGas.vue';
import AlertsCustomVisualizationResearch from '@/components/alerts/custom/visualization/AlertsCustomVisualizationResearch.vue';
import AlertsCustomVisualizationOnchain from '@/components/alerts/custom/visualization/AlertsCustomVisualizationOnchain.vue';
import AlertsCreateVisualizationUnlock from './AlertsCreateVisualizationUnlock.vue';
import AlertsCustomVisualizationPair from '@/components/alerts/custom/visualization/AlertsCustomVisualizationPair.vue';
import useHttp from '@/composeables/http';

const emitter = useEmitter();
const $http = useHttp();
const baseName = ref('AlertsCreateForm');
const store = useStore();

const isDataReady = ref({ coins: true, table: true });
const alertConfig = ref({});
const selectedAlertType = ref(
  props.entity || window.localStorage.getItem(constructLocalStorageName('update:alert-type'))
);
function constructLocalStorageName(s) {
  let path = store.state.path
    .split('.')
    .map((s, i) => {
      return i == 0 ? s : s.charAt(0).toUpperCase() + s.slice(1);
    })
    .join('');
  s = s.charAt(0).toUpperCase() + s.slice(1);
  return `${path}${s}`;
}
onMounted(() => {
  emitter.$on('config:alert', data => {
    alertConfig.value = data.config;
  });
  emitter.$on('update:alert-type', data => {
    selectedAlertType.value = data.alertType;
  });
});

const props = defineProps({
  alert: {
    type: Object,
    default: null
  },
  entity: {
    type: String,
    default: null
  }
});
</script>
